/* eslint-disable no-inline-style */
import { LAST_SYNC_AGE } from 'appModule/roles/patient/constants/constants';
import _ from 'lodash';
import BigNumber from 'bignumber.js';
import { fetchGoThumbSignedUrl } from 'utilsModule/gothumb';

/**
 * This function changes the given string to title case
 *
 * '_' will be replaced with space
 *
 * @param {string} str - String to change to title case
 */
const toTitleCase = (str) =>
  str
    .toLowerCase()
    .replace(/_/g, ' ')
    .replace(/(?: |\b)(\w)/g, (key) => key.toUpperCase());

/**
 * This function masks the given string
 *
 * Given string must contain only one '@'.
 *
 * @param {string} email - Email String
 */
const maskEmail = (email) => {
  const split = email.split('@');
  return `${email.substr(0, 4)}${new Array(split[0].length - 4).fill('*').join('')}@${split[1]}`;
};

/**
 * This function truncates the given string by the given number of characters using an ellipsis
 *
 * @param {string} str - String to be truncated
 * @param {number} [noOfChars] - Maximum number of chars to display including the ellipsis [3 chars] (default 40)
 */
const truncateWithEllipsis = (str, noOfChars = 40) => {
  const options = {
    length: noOfChars,
    omission: '...',
  };
  return _.truncate(str, options);
};

/**
 * This function renames the web/tab title using React's document.title overwrite
 *
 * @param {string} [role] - User Role String
 * @param {string} [orgType] - User Org String
 */
const renameDocumentTitle = (role, orgType) => {
  let title = 'HealthBeats®';

  if (role === 'ADMIN') title += ` - ${toTitleCase(orgType)}`;
  if (role) title += ` - ${toTitleCase(role)}`;

  document.title = title;
};

const inputNumberLimit = (event, maxNumberBelow, maxDecimal) => {
  const {
    target: { value },
  } = event;
  const reg = new RegExp(`^\\d+(\\.\\d{0,${maxDecimal}})?$`);
  if ((reg.test(value) && value < maxNumberBelow) || !value) {
    return true;
  }
  event.preventDefault();
};

// To fix imprecise calculations due to 64 bit systems as documented at
// https://javascript.info/number#imprecise-calculations
export function roundFloatToFixedDecimal(numValue, numDecimals) {
  // Return NaN if value is null or undefined
  if (numValue == null || numValue == undefined) return NaN;
  // Return value untouched if number of decimals is undefined or null
  if (isNaN(numDecimals) || numDecimals == null || numDecimals == undefined) {
    return numValue;
  } else {
    const x = new BigNumber(numValue);
    return x.decimalPlaces(parseInt(numDecimals)).toNumber();
  }
}

const listOptions = {
  az: [
    { value: 'ASC', label: 'A to Z' },
    { value: 'DESC', label: 'Z to A' },
  ],
  ascDesc: [
    { value: 'ASC', label: 'Ascending' },
    { value: 'DESC', label: 'Descending' },
  ],
  gender: [
    { value: 'FEMALE', label: 'Female first' },
    { value: 'MALE', label: 'Male first' },
    { value: 'OTHERS', label: 'Other first' },
    { value: 'UNKNOWN', label: 'Unknown first' },
    { value: 'UNSPECIFIED', label: 'Unspecified first' },
  ],
  shortLong: [
    { value: 'ASC', label: 'Shortest to Longest' },
    { value: 'DESC', label: 'Longest to Shortest' },
  ],
  enrolledStatus: [
    { value: 'ENROLLED', label: 'Enrolled first' },
    { value: 'NOT_ENROLLED', label: 'Not Enrolled first' },
    // { value: 'DISCHARGED', label: 'Discharged first' }
  ],
  oldNew: [
    { value: 'DESC', label: 'Newest to Oldest' },
    { value: 'ASC', label: 'Oldest to Newest' },
  ],
  alertType: [
    { value: 'CRITICALLY_LOW', label: 'Critically Low first' },
    { value: 'LOW', label: 'Low first' },
    { value: 'CRITICALLY_HIGH', label: 'Critically High first' },
    { value: 'HIGH', label: 'High first' },
    { value: 'MISSED', label: 'Missed first' },
  ],
  statusAlert: [
    { value: 'NEW', label: 'New first' },
    { value: 'REVIEWED', label: 'Reviewed first' },
    { value: 'CLOSED', label: 'Closed first' },
  ],
  lastSync: [
    { value: 'ASC', label: 'Ascending' },
    { value: 'DESC', label: 'Descending' },
  ],
  chargeCodeTypeSorting: [
    { value: 'base', label: 'Base Subscription first' },
    { value: 'addon_after', label: 'Add-On (After) first' },
    { value: 'addon_during', label: 'Add-On (During) first' },
    { value: 'consumables', label: 'Consumables first' },
    { value: 'kiosk', label: 'Kiosk first' },
  ],
  price: [
    { value: 'ASC', label: 'Low to High' },
    { value: 'DESC', label: 'High to Low' },
  ],
  chargeCodeType: [
    { value: 'base', label: 'Base Subscription' },
    { value: 'addon_after', label: 'Add-On (After)' },
    { value: 'addon_during', label: 'Add-On (During)' },
    { value: 'kiosk', label: 'Kiosk Subscription' },
  ],
};

const listSortedDuration = (subscriptionPeriodType) => {
  if (subscriptionPeriodType === 'DAY') return 1;
  if (subscriptionPeriodType === 'WEEK') return 7;
  if (subscriptionPeriodType === 'MONTH') return 30;
  if (subscriptionPeriodType === 'YEAR') return 365;
};

const participantListFilterOptions = [
  {
    columnTitle: 'Adherence',
    columnName: 'adherence',
    options: [
      { name: 'All', value: 'ALL' },
      { name: '25.0% and below', value: '25_AND_BELOW' },
      { name: '25.1% - 50.0%', value: '25.1_50' },
      { name: '50.1% - 75.0%', value: '50.1_75' },
      { name: '75.1% and above', value: '75.1_ABOVE' },
    ],
  },
  {
    columnTitle: 'Age',
    columnName: 'age',
    options: [
      { name: 'All', value: 'ALL' },
      { name: '25 and below', value: '25_AND_BELOW' },
      { name: '26 - 40', value: '26_40' },
      { name: '41 - 60', value: '41_60' },
      { name: '61 - 80', value: '61_80' },
      { name: '81 and above', value: '81_ABOVE' },
    ],
  },
  {
    columnTitle: 'Gender',
    columnName: 'gender',
    options: [
      { name: 'All', value: 'ALL' },
      { name: 'Female', value: 'FEMALE' },
      { name: 'Male', value: 'MALE' },
      { name: 'Others', value: 'OTHERS' },
      { name: 'Unknown', value: 'UNKNOWN' },
      { name: 'Unspecified', value: 'UNSPECIFIED' },
    ],
  },
  {
    columnTitle: 'Last Measurement',
    columnName: 'lastMeasurement',
    options: [
      { name: 'All', value: 'ALL' },
      { name: 'Critically Low', value: 'LL' },
      { name: 'Critically High', value: 'HH' },
      { name: 'Normal', value: 'N' },
      { name: 'Low', value: 'L' },
      { name: 'High', value: 'H' },
    ],
  },
  {
    columnTitle: 'Onboarded',
    columnName: 'onboarded',
    options: [
      { name: 'All', value: 'ALL' },
      { name: 'Yes', value: 'YES' },
      { name: 'No', value: 'NO' },
    ],
  },
  {
    columnTitle: 'Status',
    columnName: 'enrolledProgramStatus',
    options: [
      { name: 'All', value: 'ALL' },
      { name: 'Enrolled', value: 'ENROLLED' },
      // { name: 'Discharged', value: 'DISCHARGED' },
      { name: 'Not Enrolled', value: 'NOT_ENROLLED' },
    ],
  },
];

const alertListFilterOptions = [
  {
    columnTitle: 'Alert Types',
    columnName: 'alertType',
    options: [
      { name: 'All', value: 'ALL' },
      { name: 'Critically Low', value: 'CRITICALLY_LOW' },
      { name: 'Critically High', value: 'CRITICALLY_HIGH' },
      { name: 'Missed', value: 'MISSED' },
      { name: 'Low', value: 'LOW' },
      { name: 'High', value: 'HIGH' },
    ],
  },
  {
    columnTitle: 'Status',
    columnName: 'alertStatus',
    options: [
      { name: 'All', value: 'ALL' },
      { name: 'New', value: 'new' },
      { name: 'Reviewed', value: 'REVIEWED' },
      { name: 'Closed', value: 'CLOSED' },
    ],
  },
  // {
  //   columnTitle: 'Type of Care Task',
  //   columnName: 'type',
  //   options: [
  //     { name: 'All', value: 'ALL' },
  //     { name: 'Scheduled Task', value: 'scheduled' },
  //     { name: 'One-Time Task', value: 'one-time' },
  //   ],
  // },
];

const alertSummaryListFilterOptions = [
  {
    columnTitle: 'Alert Types',
    columnName: 'alertType',
    options: [
      { name: 'All', value: 'ALL' },
      { name: 'Continuous', value: 'CONTINUOUS' },
      { name: 'Spot', value: 'NON_CONTINUOUS' },
    ],
  },
  {
    columnTitle: 'Threshold Types',
    columnName: 'thresholdType',
    options: [
      { name: 'All', value: 'ALL' },
      { name: 'CH / CL', value: 'CRITICALLY_HIGH_CRITICALLY_LOW' },
      { name: 'H / L', value: 'HIGH_LOW' },
      { name: 'Missed', value: 'MISSED' },
    ],
  },
];

export const getBackgroundColorByGender = (gender) => {
  const ua = navigator.userAgent.toLowerCase();
  const isSafari = ua.indexOf('safari') !== -1 && ua.indexOf('chrome') === -1;

  if (gender === 'Male')
    return isSafari
      ? 'linear-gradient(rgba(255, 255, 255, 0), #48CAE4)'
      : 'linear-gradient(rgba(202, 240, 248),transparent)';
  else if (gender === 'Female')
    return isSafari
      ? 'linear-gradient(rgba(255, 255, 255, 0), #FFB5A7)'
      : 'linear-gradient(rgba(250, 225, 221),transparent)';
  return isSafari
    ? 'linear-gradient(rgba(255, 255, 255, 0), #9E9E9E)'
    : 'linear-gradient(rgba(224, 224, 224),transparent)';
};

export const getBackgroundByGender = (gender) => {
  if (gender === 'Male') return '#48CAE4';
  else if (gender === 'Female') return '#FFB5A7';
  return '#9E9E9E';
};

const validateQuery = (value, type) => {
  if (type == 'PAGE') {
    return parseInt(value) > 0;
  } else if (type == 'PAGE_SIZE') {
    return [10, 20, 50].includes(parseInt(value));
  }
};

const fetchImages = async (profile_photos = []) => {
  const images = [];
  for(const e of profile_photos) {
    const realImageUrl = await fetchGoThumbSignedUrl(e);
    images.push({
      avatar: e,
      realImageUrl,
    });
  }
  
  return images;
};

export default {
  toTitleCase,
  maskEmail,
  truncateWithEllipsis,
  renameDocumentTitle,
  inputNumberLimit,
  listOptions,
  listSortedDuration,
  participantListFilterOptions,
  alertListFilterOptions,
  alertSummaryListFilterOptions,
  getBackgroundColorByGender,
  getBackgroundByGender,
  validateQuery,
  fetchImages,
};
